import React from 'react'
import { Text } from '../../primitives'

export const HeadingMedium = ({ title, ...props }) => {
  return (
    <Text fontSize={[8, 10]} fontWeight={500} {...props}>
      {title}
    </Text>
  )
}
