import React, { useEffect, useState } from 'react'
import Layout from '../components/layouts'
import axios from 'axios'
import { Footer } from '../components/shareable/footer/Footer'
import { Flex, ImageCover, Text } from '../components/primitives'
import { HeadingMedium } from '../components/shareable/text/HeadingMedium'

const getUrl = () => {
  if (window.location.origin === 'https://stage.hemset.se') {
    return 'https://adminstage.hemset.se/api'
  }

  if (process.env.NODE_ENV !== 'production') {
    return 'http://localhost:4000'
  }

  return 'https://admin.hemset.se/api'
}

export default (props) => {
  const [posts, setPosts] = useState(null)
  const [modalIsOpen, setIsModalOpen] = useState(false)

  useEffect(() => {
    async function fetchMyAPI() {
      let url = getUrl() + '/getInstagramPosts'

      const result = await axios.get(url)
      if (result.data.success) {
        setPosts(result.data.posts)
      }
    }

    fetchMyAPI()
  }, [])

  return (
    <Layout
      headTitle={'#hemset i sociala medier | Hemset.se'}
      metaDesc={
        'Visa din hemsetup med oss och låt fler inspireras av det nya sättet att inreda! Lägg upp en bild på instagram och tagga #hemset.'
      }
      blackNav={true}
      modelOpenProps={modalIsOpen}
      onCloseModal={() => {
        setIsModalOpen(false)
      }}
      >
      <Flex minHeight={'100vh'} flexDirection={'column'} alignItems={'center'}>
        <HeadingMedium mt={'100px'} mb={6} title={'#hemset'} />
        <Text textAlign={'center'} pl={2} pr={2}>
          Visa din hemsetup med oss! Få fler att inspireras av det nya sättet
          att inreda! <br /> Lägg upp en bild på instagram och tagga #hemset.
        </Text>

        <Flex
          jusifyContent={'center'}
          mt={10}
          mb={4}
          maxWidth={'1000px'}
          width={'100%'}
          flexWrap={'wrap'}>
          {posts &&
            posts.map((post) => {
              return (
                <ImageCover
                  style={{ cursor: 'pointer' }}
                  onClick={() => {
                    window.open(
                      'https://instagram.com/p/' + post.igId,
                      '_blank'
                    )
                  }}
                  m={1}
                  width={'200px'}
                  height={'200px'}
                  src={
                    'https://instagram.com/p/' + post.igId + '/media/?size=m'
                  }
                />
              )
            })}
        </Flex>
      </Flex>
      <Footer
        actionClick={() => {
          setIsModalOpen(true)
        }}
      />
    </Layout>
  )
}
